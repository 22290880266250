import { render, staticRenderFns } from "./CustomImageUpload.vue?vue&type=template&id=64255a7b&scoped=true&"
import script from "./CustomImageUpload.vue?vue&type=script&lang=js&"
export * from "./CustomImageUpload.vue?vue&type=script&lang=js&"
import style0 from "./CustomImageUpload.vue?vue&type=style&index=0&id=64255a7b&lang=css&scoped=true&"
import style1 from "./CustomImageUpload.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64255a7b",
  null
  
)

export default component.exports