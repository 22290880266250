<template>
  <b-modal
    id="add-update-service-modal"
    size="lg"
    :ok-title="`${editing ? 'Update': 'Add'} Service`"
    cancel-variant="outline-danger"
    @ok="addService"
    @hide="()=>{$emit('close'); image=null}"
    @close="()=>{$emit('close'); image=null}"
  >
    <b-row>
      <validation-observer ref="serviceForm">
        <b-col cols="12">
          <validation-provider
            #default="{errors}"
            name="Service Title"
            rules="required"
          >
            <b-form-group label="Service Title">
              <b-form-input
                v-model="form.name"
                type="text"
                placeholder="Service Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <label>Title Alignment</label>
          <b-form-select
            v-model="form.nameAlignment"
            :options="alignmentOptions"
          />

          <b-form-group label="Service Image">
            <custom-image-upload
              height="300"
              :url="image || getStorage(service.image)"
              @imageUploaded="(img,url)=>{image = url }"
              @imageRemoved="()=>{
                form.image = null
              }"
            />
            <label>Image Alignment</label>
            <b-form-select
              v-model="form.imageAlignment"
              :options="alignmentOptions"
            />

          </b-form-group>

          <validation-provider
            #default="{errors}"
            name="Description"
            rules="required"
          >
            <b-form-group label="Description">
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </validation-observer>
    </b-row>
  </b-modal>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import CustomImageUpload from './CustomImageUpload.vue'

export default {
  components: {
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    CustomImageUpload,
    BModal,
    BRow,
    BCol,
    BFormSelect,
    BFormGroup,
    BFormInput,
  },

  props: {
    service: {
      type: [Array, Object],
      default: () => {},
    },
    editing: {
      type: [String, Boolean],
      default: false,
    },
  },

  data() {
    return {
      form: {},
      editorOption: {
        placeholder: 'Please write some description about service',
      },
      image: null,
      alignmentOptions: [
        { value: null, text: 'Please select alignment' },
        { value: 'LEFT', text: 'Left' },
        { value: 'RIGHT', text: 'Right' },
        { value: 'CENTER', text: 'Center' },
      ],
    }
  },

  watch: {
    service(value) {
      this.form = value
    },
  },

  methods: {
    addService() {
      const projectUid = this.$store.state.project.selectedProject
      if (this.editing) {
        const { id, __typename, ...form } = this.form
        if (this.image) form.image = this.image
        const formattedData = {
          id: this.service.id,
          input: form,
          projectUid,
        }

        return useApollo.cms.updateService(formattedData).then(() => {
          this.showSuccess('Service Updated Successfully')
        }).finally(() => {
          this.$emit('success')
        })
      }

      this.form.image = this.image
      return useApollo.cms.createService({ input: this.form, projectUid }).then(() => {
        this.showSuccess('Service Updated Successfully')
      }).finally(() => {
        this.$emit('success')
      })
    },
  },
}
</script>
<style lang="scss">

#add-update-service-modal {
  .modal-content {
    min-height: 400px;
  }
  .ql-editor {
    min-height: 200px;
  }
}

.dark-layout {
    .ql-snow {
      .ql-stroke {
        stroke: #a6a6a6;
      }
      .ql-fill {
        fill: #a6a6a6;
      }
      .ql-picker-label {
        color: #a6a6a6;
      }
    }
  }
</style>
