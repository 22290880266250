<template>
  <div
    class="image-upload-main w-100 bg-light-primary cursor-pointer"
    :style="`height:${height}px;`"
  >
    <b-img
      v-if="image && isImage(image)"
      :src="image"
      class="w-100 h-100 uploaded-image"
    />
    <div
      v-if="image && !isImage(image)"
      class="position-absolute file-icon"
    >
      <feather-icon
        icon="FileTextIcon"
        size="48"
      />
    </div>
    <b-form-file
      v-if="pdfAllowed"
      ref="documentUploader"
      v-model="imageFile"
    />
    <input
      v-else
      :ref="`image-uploader-${id}`"
      type="file"
      style="height:0px;width:0px;opacity:0px"
      @input="imageSelected"
    >
    <div
      v-if="!pdfAllowed"
      class="add-button"
    >
      <b-button
        ref="imageUploadButton"
        variant="primary"
        @click="pdfAllowed ? $refs.documentUploader.click() : $refs[`image-uploader-${id}`].click()"
      >
        <feather-icon
          icon="UploadIcon"
          class="mr-50"
        />
        <span
          v-if="!smallButton"
          class="align-middle"
        >{{ image ? `Change ${title}` :`Upload ${title}` }}</span>
      </b-button>
    </div>
    <div
      v-else-if="!image"
      class="upload-icon position-absolute text-center"
    >
      <feather-icon
        icon="UploadIcon"
        color="#b6b6b6"
        size="38"
      />

      <p class="mt-3 small mb-0 text-dark">
        JPG, PNG or PDF only
      </p>
      <p class="mb-0 small text-dark">
        Size Limit: 5MB
      </p>
    </div>
    <div
      v-show="image || alwaysShowDelete"
      class="delete-button"
    >
      <b-button
        v-b-tooltip.hover
        variant="danger"
        size="sm"
        :title="`Remove ${title}`"
        @click="removeImage"
      >
        <feather-icon
          icon="XIcon"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton, BImg, VBTooltip, BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BButton, BImg, BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    height: {
      type: [Number, String],
      default: 300,
    },
    title: {
      type: String,
      default: 'Image',
    },
    url: {
      type: String,
      default: null,
    },
    smallButton: {
      type: Boolean,
      default: false,
    },
    alwaysShowDelete: {
      type: Boolean,
      default: false,
    },
    pdfAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 5),
      isUploadingImage: false,
      imageFile: null,
      image: this.url,
      previousImage: null,
    }
  },
  watch: {
    url(url) {
      if (url === 'https://via.placeholder.com/350x150') {
        this.$refs[`image-uploader-${this.id}`].value = null
        this.image = null
      }
      this.image = url
    },
    imageFile() {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          if (this.imageFile.size > 1024 * 1024 * 5) {
            this.showError(`Please select ${this.pdfAllowed ? 'document' : 'images'} of less than 5MB`)
            this.$refs[`image-uploader-${this.id}`].value = null
            return
          }
          this.previousImage = this.image
          this.image = reader.result
          this.$emit('imageUploaded', this.imageFile, this.image)
        },
        false,
      )
      if (this.imageFile) {
        if (this.pdfAllowed && (/\.(pdf|jpe?g|png)$/i.test(this.imageFile.name))) {
          reader.readAsDataURL(this.imageFile)
        } else if (!this.pdfAllowed && (/\.(jpe?g|png|gif)$/i.test(this.imageFile.name))) {
          reader.readAsDataURL(this.imageFile)
        } else if (this.pdfAllowed) {
          this.showError('The accepted document formats are JPG, PNG or PDF')
          this.resetImage()
        } else {
          this.showError('The accepted image formats are JPG, GIF or PNG')
          this.resetImage()
        }
      }
    },
  },

  methods: {
    resetImage() {
      this.imageFile = null
      this.image = null
      this.$refs.documentUploader.reset()
    },
    removeImage() {
      this.imageFile = null
      this.image = null
      this.previousImage = null
      if (this.$refs[`image-uploader-${this.id}`]) this.$refs[`image-uploader-${this.id}`].value = null
      this.$emit('imageRemoved')
    },
    imageSelected(e) {
      if (!e.target.files[0]) return
      // eslint-disable-next-line prefer-destructuring
      this.imageFile = e.target.files[0]
    },
    isImage(image) {
      const img = image.split(';')[0]?.split('/')
      if (img[1] !== 'pdf') return true
      return false
    },
  },
}
</script>

<style lang="css" scoped>
.image-upload-main{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
}
.uploaded-image{
    position: absolute;
    object-fit: cover;
    z-index: 1;
    opacity: 0.5;
    border-radius: 8px;
}
  .add-button,.delete-button{
    z-index: 2;
  }
.delete-button{
  position: absolute;
  left: 8px;
  right: 8px;
  top: 8px;
}
.file-icon {
  padding-bottom: 70px;
}
.upload-icon {
  top: 58px;
}
</style>

<style lang="scss">
.image-upload-main {
  .b-form-file {
    height: 100%;
    width: 100%;
  }
  .custom-file-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }
  .custom-file-label:after {
    display: none;
  }
}
</style>
