<template>
  <div>
    <b-row class="mt-2 p-2 ">
      <b-col cols="12">
        <service-section :editable="true" />
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import ServiceSection from '../components/ServiceSection.vue'

export default {
  components: {
    ServiceSection,
    BRow,
    BCol,
  },

  methods: {
    showServiceModal() {
      this.$refs.serviceSectionModal.show()
    },
  },
}
</script>
