<template>
  <section
    class="services-section section-gap home-one pt-3"
    data-aos="zoom-in"
    data-aos-duration="1000"
  >
    <div class="container">
      <div class="row d-flex justify-content-center section-title">
        <div class="col-lg-6 col-md-8 col-12 text-center">
          <h1 class="service-title">
            {{ serviceSection.title }}
          </h1>
          <input
            v-if="canUpdate"
            v-model="serviceSection.title"
            class="form-control"
            @change=" updateSection()"
          >
          <p class="pt-1">
            {{ serviceSection.description }}
          </p>
          <textarea
            v-if="canUpdate"
            v-model="serviceSection.description"
            class="p-1 w-100"
            @change=" updateSection()"
          />
        </div>
      </div>
      <div class="row d-flex justify-content-center py-3">
        <div
          v-for="service in services"
          :key="service.name"
          class="col-lg-4 col-md-6 col-12"
        >
          <div
            class="single-service position-relative"
          >
            <div @click="showServiceDetails(service)">
              <div :class="`text-${service.imageAlignment.toLowerCase()}`">
                <img
                  :src="getStorage(service.image)"
                  alt="/"
                >
              </div>
              <h4 :class="`text-${service.nameAlignment.toLowerCase()}`">
                {{ service.name }}
              </h4>
              <p v-html="shortDescription(service.description)" />
            </div>
            <div
              v-if="canUpdate"
              class="position-absolute service-action d-none"
              style="top:5px;left:78%;"
            >
              <feather-icon
                icon="EditIcon"
                size="20"
                class="text-primary"
                @click="editService(service)"
              />
              <feather-icon
                icon="TrashIcon"
                size="20"
                class="text-danger ml-2"
                @click="deleteService(service)"
              />
            </div>
          </div>

        </div>
        <div
          v-if="canUpdate"
          class="col-lg-4 col-md-6 col-12"
          @click="addNewService"
        >
          <div class="single-service text-center">
            <feather-icon
              class="font-weight-700 text-primary"
              icon="PlusIcon"
              size="100"
            />
            <h4
              style="font-weight:600"
            >
              Add New Service
            </h4>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="service-detail-modal"
      ref="serviceModal"
      hide-footer
      size="lg"
      centered
    >
      <b-row
        v-if="!!activeService.name"
      >
        <b-col md="12">
          <h2 :class="`text-${activeService.nameAlignment.toLowerCase()}`">
            {{ activeService.name }}
          </h2>
        </b-col>
        <b-col
          md="12"
          class="py-2"
        >
          <p v-html="activeService.description" />
        </b-col>
      </b-row>
    </b-modal>

    <add-update-service
      v-if="canUpdate"
      :service="editingService"
      :editing="!!editingService.id"
      @close="closedAddEditServiceModal"
      @success="getServices()"
    />
  </section>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'
import {
  BModal, BRow, BCol,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import AddUpdateService from './AddUpdateService.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    AddUpdateService,

  },
  props: {
    canUpdate: {
      type: [Boolean, String],
      default: false,
    },
    serviceSection: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      services: [],
      activeService: {},
      editingService: {},
      storageUrl: process.env.VUE_APP_STORAGE_URL,

    }
  },

  mounted() {
    this.getServices()
  },

  methods: {
    showServiceDetails(service) {
      this.activeService = service
      this.$refs.serviceModal.show()
    },

    getServices() {
      useApollo.cms.getServices().then(res => {
        this.services = res.data.services.data
      })
    },

    addNewService() {
      this.$bvModal.show('add-update-service-modal')
    },

    closedAddEditServiceModal() {
      this.editingService = {}
    },

    editService(service) {
      this.editingService = service
      this.$bvModal.show('add-update-service-modal')
    },

    deleteService(service) {
      const projectUid = this.$store.state.project.selectedProject
      useApollo.cms.deleteService({ id: service.id, projectUid }).then(() => {
        this.showSuccess('Service Deleted successfully')
      }).finally(() => {
        this.getServices()
      })
    },

    updateSection() {
      this.$forceUpdate()
      const projectUid = this.$store.state.project.selectedProject
      const formData = {
        title: this.serviceSection.title,
        description: this.serviceSection.description,
        section: 'our services',
        imageAlignment: 'LEFT',
      }

      useApollo.cms.updateSection({ id: this.serviceSection.id, projectUid, input: formData }).then(() => {
        this.$emit('sectionUpdated')
      })
    },

    shortDescription(desc) {
      return `${desc}`
    },
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/splash-screen.scss';

</style>

<style lang="scss">
.ql-align-center{
  text-align: center !important;
}

.ql-align-left{
  text-align: left !important;
}

.ql-align-right{
  text-align: right !important;
}</style>
